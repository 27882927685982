import type { ToastOptions } from 'vue-toastification/dist/types/types'
import AppNotification from '@/components/AppNotification.vue'
import defu from 'defu'
import { useToast } from 'vue-toastification'

const VNodeNotification = (params: { title?: string, content?: string }) => h(AppNotification, { title: params.title, content: params.content })

interface NotificationOptions extends Omit<ToastOptions, 'type'> {
  title?: string
  content?: string
}

const notificationDefaultOptions: NotificationOptions = {
  title: '',
  content: '',
}

export function notifyError(options: NotificationOptions) {
  const toast = useToast()

  toast.error(
    VNodeNotification({ title: options.title, content: options.content }),
    defu(options, notificationDefaultOptions),
  )
}

export function notifySuccess(options: NotificationOptions) {
  const toast = useToast()

  toast.success(
    VNodeNotification({ title: options.title, content: options.content }),
    defu(options, notificationDefaultOptions),
  )
}

export function notifyWarning(options: NotificationOptions) {
  const toast = useToast()

  toast.warning(
    VNodeNotification({ title: options.title, content: options.content }),
    defu(options, notificationDefaultOptions),
  )
}

export function notifyInfo(options: NotificationOptions) {
  const toast = useToast()

  toast.info(
    VNodeNotification({ title: options.title, content: options.content }),
    defu(options, notificationDefaultOptions),
  )
}
