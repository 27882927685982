export const unoTheme = {
  colors: {
    'primary': '#7367F0',
    'primary-darken-1': '#675DD8',
    'secondary': '#808390',
    'secondary-darken-1': '#737682',
    'success': '#28C76F',
    'success-darken-1': '#24B364',
    'info': '#00BAD1',
    'info-darken-1': '#00A7BC',
    'warning': '#FF9F43',
    'warning-darken-1': '#E68F3C',
    'error': '#FF4C51',
    'error-darken-1': '#E64449',
    'background': '#F8F7FA',
    'surface': '#fff',
    'grey': {
      light: '#FAFAFC',
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    'dark': {
      50: '#E7E9F6',
      100: '#CFD3EC',
      200: '#B6BEE3',
      300: '#AAB3DE',
      400: '#7983BB',
      500: '#5E6692',
      600: '#4A5072',
      700: '#26293A',
      800: '#2F3349',
      900: '#26293A',
    },
  },
}
