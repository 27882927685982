import { type CommonRole, type LoggedInUser, UserDashboard } from '@/types/user'

export function getDashboard(user: LoggedInUser, dashboard?: string) {
  const isIssuer = user.is_issuer || user.manage_companies.length > 0
  const isInvestor = user.is_investor || user.manage_investors.length > 0
  const isBoardMember = user.is_board_member
  const isProxy = user.is_proxy
  const isGuest = user.is_guest

  if (dashboard) {
    if (dashboard === UserDashboard.ISSUERS && isIssuer)
      return UserDashboard.ISSUERS
    if (dashboard === UserDashboard.INVESTORS && isInvestor)
      return UserDashboard.INVESTORS
    if (dashboard === UserDashboard.BOARD_MEMBER && isBoardMember)
      return UserDashboard.BOARD_MEMBER
    if (dashboard === UserDashboard.PROXY && isProxy)
      return UserDashboard.PROXY
    if (dashboard === UserDashboard.EXTERNAL && isGuest)
      return UserDashboard.EXTERNAL
  }
  if (isIssuer)
    return UserDashboard.ISSUERS
  if (isInvestor)
    return UserDashboard.INVESTORS
  if (isBoardMember)
    return UserDashboard.BOARD_MEMBER
  if (isProxy)
    return UserDashboard.PROXY
  if (isGuest)
    return UserDashboard.EXTERNAL
  return UserDashboard.EXTERNAL
}

export function getUserFromCookie(user: string | undefined | null) {
  try {
    return user ? JSON.parse(user) : user
  }
  catch {
    return user
  }
}

export function getDashboardPath(selectedRole: CommonRole) {
  let path = ''

  switch (selectedRole.dashboard) {
    case UserDashboard.ISSUERS:
      path = `/issuers/${selectedRole.id}/overview`
      break
    case UserDashboard.INVESTORS:
      path = `/investors/${selectedRole.id}/overview`
      break
    case UserDashboard.PROXY:
      path = '/proxy/meetings-votings'
      break
    case UserDashboard.EXTERNAL:
      path = '/external/meetings-votings'
      break
    case UserDashboard.BOARD_MEMBER:
      path = '/board-member/overview'
      break
    default:
      path = '/error/error-404'
      break
  }
  return path
}
