<script setup lang="ts">
interface Props {
  title?: string
  content?: string
}
const props = defineProps<Props>()
</script>

<template>
  <div class="toastify-message-container">
    <div v-if="props.title" class="toast-header">
      <strong>{{ props.title }}</strong>
    </div>
    <div v-if="props.content" class="toast-body">
      <span v-html="props.content" />
    </div>
  </div>
</template>
