import { SubscriptionPlan } from '@/constants/subscription'
import { useLayoutConfigStore } from '@layouts/stores/config'

export default defineNuxtRouteMiddleware(async (to) => {
  const subscriptionStore = useSubscriptionStore()
  const configStore = useLayoutConfigStore()

  if ((to.name === 'issuers-issuerId-documents' || to.name === 'issuers-issuerId-confirmations') && subscriptionStore.currentSubscriptionPlan?.name === SubscriptionPlan.BASIC) {
    // TODO Open dialog with enum
    configStore.isPricingPlanDialogVisible = true

    return abortNavigation()
  }
})
