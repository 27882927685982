import { match, P } from 'ts-pattern'

function canGoNext() { /* does nothing on purpose */ }

export default defineNuxtRouteMiddleware(async (to) => {
  /*
     * If it's a public route, continue navigation. This kind of pages are allowed to visited by login & non-login users. Basically, without any restrictions.
     * Examples of public routes are, 404, under maintenance, etc.
     */
  if (to.meta.public || to.query.logout)
    return

  const authStore = useAuthStore()

  if (authStore.isAuthenticated) {
    if (!authStore.currentUser)
      await authStore.getUser()
  }

  return match([authStore.isAuthenticated, to.meta.unauthenticatedOnly, to.name])
    .with([false, true, P.any], canGoNext)
    .with([false, P.nullish.or(false), P.not('auth-login')], () => {
      return navigateTo({
        name: 'auth-login',
        query: {
          ...to.query,
          to: to.fullPath !== '/' ? to.path : undefined,
        },
      })
    })
    .with([true, true, P.any], () => {
      return navigateTo('/')
    })
    .otherwise(canGoNext)
})
