import type { CommonRole, LoggedInUser, ManageCompany, ManageInvestor } from '@/types/user'
import { UserDashboard } from '@/types/user'
import { uniqBy } from 'lodash-es'

function reduceManagedCompanies(companies: ManageCompany[]) {
  return companies.reduce(
    (acc, cur) => {
      if (cur.pivot?.is_confirmed)
        acc.push({ ...cur, name: `${cur.name} (Co-manage)` })

      return acc
    },
    [] as ManageCompany[],
  )
}

function reduceManagedInvestors(investors: ManageInvestor[]) {
  return investors.reduce(
    (acc, cur) => {
      if (cur.pivot?.is_confirmed)
        acc.push({ ...cur, name: `${cur.name} (Co-manage)` })

      return acc
    },
    [] as ManageInvestor[],
  )
}

export function useRole(currentUser: Ref<LoggedInUser | undefined>) {
  const currentManagedCompanies = computed(() => {
    if (!currentUser.value)
      return []

    return uniqBy([
      ...(currentUser.value.company_boards || []).map(item => item.company),
      ...(currentUser.value.own_companies || []),
      ...reduceManagedCompanies(currentUser.value.manage_companies || []),
    ], 'id')
  })

  const currentManagedInvestors = computed(() => {
    if (!currentUser.value)
      return []

    return uniqBy([
      ...(currentUser.value.own_investors || []),
      ...reduceManagedInvestors(currentUser.value.manage_investors || []),
    ], 'id')
  })

  const switchableRoles = computed(() => {
    return [
      ...currentManagedCompanies.value.map(item => ({
        id: item.id,
        key: `company_${item.id}`,
        originName: item.name,
        name: `Company - ${item.name}`,
        logo_url: item.logo_url,
        dashboard: UserDashboard.ISSUERS,
      })),
      ...currentManagedInvestors.value.map(item => ({
        id: item.id,
        key: `investor_${item.id}`,
        originName: item.name,
        name: `Investor - ${item.name}`,
        logo_url: item.logo_url,
        dashboard: UserDashboard.INVESTORS,
      })),
      currentUser.value?.is_board_member && {
        id: UserDashboard.BOARD_MEMBER,
        key: 'board_member',
        name: 'Board Member',
        logo_url: null,
        dashboard: UserDashboard.BOARD_MEMBER,
      },
      currentUser.value?.is_proxy && {
        id: UserDashboard.PROXY,
        key: 'proxy',
        name: 'Proxy',
        logo_url: null,
        dashboard: UserDashboard.PROXY,
      },
      currentUser.value?.is_guest && {
        id: UserDashboard.EXTERNAL,
        key: 'external',
        name: 'External',
        logo_url: null,
        dashboard: UserDashboard.EXTERNAL,
      },
    ].filter(Boolean) as CommonRole[]
  })

  return {
    currentManagedCompanies,
    currentManagedInvestors,
    switchableRoles,
  }
}
