export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error: any, instance, info) => {
    console.error(error)

    showError({
      statusCode: 500,
      statusMessage: error?.message || 'An error occurred',
      data: {
        message: error?.message,
        stack: error?.stack,
        instance,
        info,
      },
    })
  }
})
