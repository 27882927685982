import validate from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45authentication_45global from "/builds/konsento-ag-app/frontend/middleware/01.authentication.global.ts";
import _02_45dashboard_45global from "/builds/konsento-ag-app/frontend/middleware/02.dashboard.global.ts";
import _03_45authorization_45global from "/builds/konsento-ag-app/frontend/middleware/03.authorization.global.ts";
import _04_45subscription_45global from "/builds/konsento-ag-app/frontend/middleware/04.subscription.global.ts";
import manifest_45route_45rule from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45authentication_45global,
  _02_45dashboard_45global,
  _03_45authorization_45global,
  _04_45subscription_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}