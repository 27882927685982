<script setup lang="ts">
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import misc404 from '@images/pages/404.png'
import miscMaskDark from '@images/pages/misc-mask-dark.png'
import miscMaskLight from '@images/pages/misc-mask-light.png'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  error: any
}>()

const authThemeMask = useGenerateImageVariant(miscMaskLight, miscMaskDark)

const isDev = import.meta.dev

const errToShow = computed(() => {
  switch (props.error?.statusCode) {
    case 404:
      return {
        status: 404,
        title: 'Page Not Found',
        description: 'We couldn\'t find the page you are looking for.',
      }
    case 401:
      return {
        status: 401,
        title: 'You are not authorized! 🔐',
        description: 'You don\'t have permission to access this page. Go Home!',
      }
    default:
      if (isDev) {
        return {
          status: 500,
          title: props.error?.statusMessage,
          description: props.error.data?.info,
        }
      }
      else {
        return {
          status: 500,
          title: 'Oops! Something went wrong.',
          description: 'We are working on it and we\'ll get it fixed as soon as we can',
        }
      }
  }
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <NuxtLayout name="blank">
    <div class="misc-wrapper :uno:">
      <ErrorHeader
        :status-code="errToShow.status"
        :title="errToShow.title"
        :description="errToShow.description"
      />

      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="errToShow.status === 500"
        style="max-inline-size: 80dvw; overflow-x: scroll;"
        v-html="error.stack"
      />
      <!-- eslint-enable -->

      <AppButton
        class="k-6gxxuq mb-11"
        size="default"
        @click="handleError"
      >
        Back to Home
      </AppButton>

      <!-- 👉 Image -->
      <div class="misc-avatar w-100 text-center">
        <VImg
          :src="misc404"
          alt="error 404"
          :max-height="$vuetify.display.smAndDown ? 350 : 500"
          class="mx-auto"
        />
      </div>

      <img
        class="k-6z3v5x misc-footer-img"
        :src="authThemeMask"
        alt="misc-footer-img"
        height="320"
      >
    </div>
  </NuxtLayout>
</template>

<style lang="scss">
@use "@core/scss/template/pages/misc";
</style>
