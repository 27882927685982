import { UserDashboard } from '@/types/user'
import { match } from 'ts-pattern'

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore()

  if (!authStore.isAuthenticated)
    return

  // return if route is setting route such as user-profile, 2FA-setting
  if (to.meta.isSetting) {
    return
  }

  const currentRoleId = Number((to.params as any).issuerId || (to.params as any).investorId)

  await match(authStore.currentDashboard)
    .with(UserDashboard.ISSUERS, authStore.getDashboardData)
    .with(UserDashboard.INVESTORS, authStore.getDashboardData)
    .otherwise(() => {})

  if (!authStore.currentRoleId && [UserDashboard.ISSUERS, UserDashboard.INVESTORS].includes(authStore.currentDashboard))
    return

  if (!to.name.startsWith(authStore.currentDashboard)) {
    return match(authStore.currentDashboard)
      .with(UserDashboard.ISSUERS, () => navigateTo({
        name: 'issuers-issuerId-overview',
        params: { issuerId: String(authStore.currentRoleId) },
      }))
      .with(UserDashboard.INVESTORS, () => navigateTo({
        name: 'investors-investorId-overview',
        params: { investorId: String(authStore.currentRoleId) },
      }))
      .with(UserDashboard.BOARD_MEMBER, () => navigateTo({
        name: 'board-member-overview',
      }))
      .with(UserDashboard.PROXY, () => navigateTo({
        name: 'proxy-meetings-votings',
      }))
      .with(UserDashboard.EXTERNAL, () => navigateTo({
        name: 'external-meetings-votings',
      }))
      .otherwise(() => {})
  }
  else if (currentRoleId !== authStore.currentRoleId) {
    return match(authStore.currentDashboard)
      .with(UserDashboard.ISSUERS, () => {
        return navigateTo({
          name: 'issuers-issuerId-overview',
          params: { issuerId: String(authStore.currentRoleId) },
        })
      })
      .with(UserDashboard.INVESTORS, () => {
        return navigateTo({
          name: 'investors-investorId-overview',
          params: { investorId: String(authStore.currentRoleId) },
        })
      })
      .otherwise(() => {})
  }
})
