import revive_payload_client_6PFImMzl6J from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_gFodiWxSLW from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.22.0_typescript@5.4.5_vite@5.2.10_@types+n_kkr2jn4ap6pkvdhgqyedbhnmcu/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_O81JgpupZP from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.22.0_typescript@5.4.5_vite@5.2.10_@types+n_kkr2jn4ap6pkvdhgqyedbhnmcu/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_EPdLtAm2e2 from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_y0TC590xV5 from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vWuDoCi3f2 from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aAuB14va8V from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oXBv6x9rcf from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YKxP6xmAjR from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_AfOUvFFrry from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.0_typescript@5.4.5_vue@3.5.6_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/konsento-ag-app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_HzZzJqNHuM from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.7_eslint@8.57.0_ioredis@5.4.1_magicast@0._ojuei2juhscdawg4765dpmhphi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/builds/konsento-ag-app/frontend/.nuxt/unocss.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/konsento-ag-app/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_TtZW9Io8fB from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_magicast@0.3.5_rollup@4.22.0_vite@5.2.10_@types+node@20.12.7_sass@1.71.1_mn4ipjvlod2b3x3ykwn5er7aju/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import switch_locale_path_ssr_cOCbXLo5x6 from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.0_vue@3.5.6_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_dpneSxf8dN from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.0_vue@3.5.6_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_5VUc1Q3Jnc from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.5_rollup@4.22.0_webpack-sources@3.2.3/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_mBzLRlDFSN from "/builds/konsento-ag-app/frontend/modules/vuetify/runtime/plugin.ts";
import vuetify_no_client_hints_RoseI7rxDZ from "/builds/konsento-ag-app/frontend/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.22.0_typescript@5.4.5_vite@5.2.10_@types+n_kkr2jn4ap6pkvdhgqyedbhnmcu/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import index_D6fDksuOQB from "/builds/konsento-ag-app/frontend/plugins/casl/index.ts";
import index_pJsEXjjc09 from "/builds/konsento-ag-app/frontend/plugins/iconify/index.ts";
import error_ldt3PQauZ0 from "/builds/konsento-ag-app/frontend/plugins/error.ts";
import event_bus_S6Iq1iGe5d from "/builds/konsento-ag-app/frontend/plugins/event-bus.ts";
import i18n_VfGcjrvSkj from "/builds/konsento-ag-app/frontend/plugins/i18n.ts";
import layouts_mPotmY5lsV from "/builds/konsento-ag-app/frontend/plugins/layouts.ts";
import maska_UHaKf2z1iQ from "/builds/konsento-ag-app/frontend/plugins/maska.ts";
import v_phone_input_telygolbtC from "/builds/konsento-ag-app/frontend/plugins/v-phone-input.ts";
import vue_loading_overlay_JK73elCvRP from "/builds/konsento-ag-app/frontend/plugins/vue-loading-overlay.ts";
import vue_toastification_gZJw6dQQf2 from "/builds/konsento-ag-app/frontend/plugins/vue-toastification.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/builds/konsento-ag-app/frontend/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_6PFImMzl6J,
  vuetify_i18n_gFodiWxSLW,
  vuetify_icons_O81JgpupZP,
  unhead_EPdLtAm2e2,
  router_y0TC590xV5,
  payload_client_vWuDoCi3f2,
  navigation_repaint_client_aAuB14va8V,
  check_outdated_build_client_oXBv6x9rcf,
  chunk_reload_client_YKxP6xmAjR,
  plugin_vue3_AfOUvFFrry,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HzZzJqNHuM,
  unocss_MzCDxu9LMj,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_TtZW9Io8fB,
  switch_locale_path_ssr_cOCbXLo5x6,
  i18n_dpneSxf8dN,
  plugin_5VUc1Q3Jnc,
  plugin_mBzLRlDFSN,
  vuetify_no_client_hints_RoseI7rxDZ,
  index_D6fDksuOQB,
  index_pJsEXjjc09,
  error_ldt3PQauZ0,
  event_bus_S6Iq1iGe5d,
  i18n_VfGcjrvSkj,
  layouts_mPotmY5lsV,
  maska_UHaKf2z1iQ,
  v_phone_input_telygolbtC,
  vue_loading_overlay_JK73elCvRP,
  vue_toastification_gZJw6dQQf2,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]