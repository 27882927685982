import type { $Fetch } from 'ofetch'
import { StorageKey } from '@/types/storage'

export const $api = $fetch.create({
  retry: 1,
  retryDelay: 2000,
  retryStatusCodes: [500, 504],
  // Request interceptor
  async onRequest({ options }) {
    options.baseURL = useRuntimeConfig().public.apiBaseUrl as string || '/api'

    const accessToken = useLocalStorage(StorageKey.ACCESS_TOKEN, '').value

    if (accessToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      }
    }
  },
  async onResponseError(error) {
    const authStore = useAuthStore()

    switch (error.response?.status) {
      case 401:
        if (
          error.request.toString().includes('user')
          || error.request.toString().includes('logout')
        ) {
          return
        }

        try {
          await authStore.postLogout()
        }
        catch {}
        finally {
          navigateTo({ name: 'auth-login' })
          notifyError({
            content: 'Unauthorized',
          })
        }
        break
      case 503:
        navigateTo({ name: 'error-under-maintainance' })
        break
      case 500:
      case 504:
        notifyError({
          content: error.response?.statusText || 'Internal Server Error',
        })
        break
    }
  },
}) as $Fetch
