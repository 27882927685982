import type { Action, Permission } from '@/plugins/casl/ability'
import type { LoggedInUser } from '@/types/user'

export function normalizePermissions(rules: Permission[]) {
  const results: Permission[] = []

  for (const rule of rules) {
    if (rule.action === 'manage') {
      results.push(
        ...new Array<Action>('create', 'read', 'update', 'delete', 'manage').map(action => ({
          ...rule,
          action,
        })),
      )
    }
    else {
      results.push(rule)
    }
  }

  return results
}

// TODO: define permissions for each role
export function getPermissions(_user: LoggedInUser): Permission[] {
  // if (user.is_issuer || Number(user.manage_companies?.length) > 0)
  // if (user.is_investor || Number(user.manage_investors?.length) > 0)
  // if (user.is_board_member)
  // if (user.is_proxy)
  // if (user.is_guest)
  return [
    { action: 'manage', subject: 'Meeting' },
    { action: 'manage', subject: 'Investor' },
    { action: 'manage', subject: 'Agenda' },
    { action: 'manage', subject: 'BoardRole' },
    { action: 'manage', subject: 'Investment' },
    { action: 'manage', subject: 'ProxyInstruction' },
    { action: 'manage', subject: 'Share' },
    { action: 'manage', subject: 'ShareEmission' },
    { action: 'manage', subject: 'ShareLedger' },
    { action: 'manage', subject: 'ShareInstrument' },
  ]
}
