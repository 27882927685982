<script lang="ts" setup>
interface Props {
  size?: string
  variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'
  color?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
  variant: 'elevated',
  color: 'primary',
})

const { $attrs } = useAttrs()
</script>

<template>
  <VBtn
    v-bind="$attrs"
    class="app-btn"
    :size="props.size"
    :variant="props.variant"
    :color="props.color"
    :disabled="disabled"
  >
    <slot />
  </VBtn>
</template>

<style lang="scss">
@use "@layouts/styles/mixins";

.app-btn {
  border-radius: 0.3rem;
  padding-inline: 1rem;

  + .app-btn {
    margin-inline-start: 8px;
  }

  .v-btn__content {
    @include mixins.flex-box($align: center);
  }

  &:not(.v-btn--icon).v-btn--size-small {
    padding-inline: 0.5rem !important;
  }
}
</style>
