export const SubscriptionStatus = Object.freeze({
  TRIAL: 'TRIAL',
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  INACTIVE: 'INACTIVE',
})

export const SubscriptionPlan = Object.freeze({
  BASIC: 'Basic',
  PREMIUM: 'Premium',
})

export const SubscriptionAction = Object.freeze({
  UPGRADE: 'UPGRADE',
  DOWNGRADE: 'DOWNGRADE',
})

export const SubscriptionFeature = Object.freeze({
  MEETING_ATTENDANCE_SUMMARY: 'MEETING_ATTENDANCE_SUMMARY',
  DOCUMENT_TEMPLATE: 'DOCUMENT_TEMPLATE',
  MEETING_TYPE: 'MEETING_TYPE',
  FINANCIAL_INSTRUMENT_NUMBERED_SHARE: 'FINANCIAL_INSTRUMENT_NUMBERED_SHARE',
  MEETING_AGENDA_ITEM: 'MEETING_AGENDA_ITEM',
})
