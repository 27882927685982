import { canNavigate } from '@/@layouts/plugins/casl'

export default defineNuxtRouteMiddleware(async (to) => {
  /*
     * If it's a public route, continue navigation. This kind of pages are allowed to visited by login & non-login users. Basically, without any restrictions.
     * Examples of public routes are, 404, under maintenance, etc.
     */
  if (to.meta.public)
    return

  if (!canNavigate(to))
    return navigateTo({ name: 'auth-not-authorized' })
})
