import { createVPhoneInput } from 'v-phone-input'
import { VAutocomplete } from 'vuetify/components/VAutocomplete'
import 'flag-icons/css/flag-icons.min.css'
import 'v-phone-input/dist/v-phone-input.css'

export default defineNuxtPlugin((nuxtApp) => {
  const vPhoneInput = createVPhoneInput({
    countryIconMode: 'svg',
    enableSearchingCountry: true,
  })

  nuxtApp.vueApp.component('VAutocomplete', VAutocomplete)

  nuxtApp.vueApp.use(vPhoneInput)
})
